<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2611 7.72592C10.2611 7.66005 10.2399 7.60594 10.1975 7.5636L7.71038 5.07937C7.66799 5.03702 7.61382 5.01585 7.54787 5.01585C7.48193 5.01585 7.42776 5.03702 7.38536 5.07937L4.90532 7.55654C4.85821 7.613 4.83466 7.66946 4.83466 7.72592C4.83466 7.79179 4.85586 7.8459 4.89825 7.88824C4.94064 7.93059 4.99481 7.95176 5.06076 7.95176H6.64347V10.436C6.64347 10.4972 6.66584 10.5501 6.71059 10.5948C6.75534 10.6395 6.80833 10.6618 6.86957 10.6618H8.22618C8.28741 10.6618 8.3404 10.6395 8.38515 10.5948C8.4299 10.5501 8.45228 10.4972 8.45228 10.436V7.95176H10.035C10.0962 7.95176 10.1492 7.92941 10.194 7.88471C10.2387 7.84002 10.2611 7.78708 10.2611 7.72592ZM14.7831 9.75847C14.7831 10.5066 14.5181 11.1453 13.9882 11.6746C13.4583 12.2039 12.8189 12.4685 12.0699 12.4685H4.38246C3.51103 12.4685 2.7656 12.1592 2.14618 11.5405C1.52675 10.9218 1.21704 10.1772 1.21704 9.30679C1.21704 8.69515 1.38191 8.13055 1.71164 7.613C2.04137 7.09545 2.48415 6.70729 3.03998 6.44852C3.03056 6.30737 3.02585 6.20621 3.02585 6.14504C3.02585 5.14759 3.37913 4.29599 4.0857 3.59024C4.79226 2.88449 5.64485 2.53162 6.64347 2.53162C7.3783 2.53162 8.05071 2.73628 8.66071 3.14562C9.27071 3.55495 9.71467 4.09838 9.99259 4.77589C10.327 4.48418 10.718 4.33833 11.1655 4.33833C11.6648 4.33833 12.0911 4.51477 12.4444 4.86764C12.7977 5.22052 12.9743 5.64632 12.9743 6.14504C12.9743 6.50262 12.8777 6.82727 12.6846 7.11898C13.297 7.26483 13.7998 7.58359 14.1931 8.07526C14.5864 8.56694 14.7831 9.12801 14.7831 9.75847Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconUpload'
}
</script>
